.p-dialog {
  @media (min-width: 768px) {
    max-width: 75vw;
  }

  @media (min-width: 992px) {
    max-width: 50vw;
  }
}

.p-datatable.p-component .p-datatable-header {
  font-size: 1.5em;
  font-weight: 300;
}

.p-menu-list {
  .p-menuitem {
    .p-menuitem-link {
      padding: 0.5em;
    }
  }
}

.p-menubar-root-list {
  .p-menuitem {
    a {
      height: 50px;
    }
  }
}
