html,
body {
  font-size: 16px;
  margin: 0;
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
}

#root,
.login-page,
body,
html,
#root > div,
.full-height {
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

small {
  font-size: 12px;
}
