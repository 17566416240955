.loader-component {
  .p-dialog {
    box-shadow: none;
  }

  .p-content {
    background: none;
    padding: 100px;
  }

  .progress-spinner {
    .p-progress-spinner-circle {
      animation: ui-progress-apinner-dash 1.5s ease-in-out infinite, ui-progress-spinner-color 6s ease-in-out infinite;
    }
  }

  @keyframes ui-progress-spinner-color {
    100%,
    0% {
      stroke: #0859c6;
    }

    40% {
      stroke: #0859c6;
    }

    66% {
      stroke: #0859c6;
    }

    80%,
    90% {
      stroke: #0859c6;
    }
  }

  @keyframes ui-progress-apinner-dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }

    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
}
