.error-page {
  .error-code {
    font-size: 4em;
    font-weight: 400;
  }

  .error-message {
    font-size: 1.25em;
  }
}
