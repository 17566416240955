.apps-page {
  .p-grid {
    justify-content: center;
  }

  .apps-card {
    border: none !important;

    & > div {
      height: 100%;
      cursor: pointer;
    }

    img {
      width: 5em;
    }
  }
}
