#list-users {
  .column-type {
    width: 5ch;
    text-align: center;
  }
  .column-name { width: 40ch; }
  .column-uuid { width: 37ch; }
  .column-email { width: 30ch; }
  .column-cpf { width: 15ch; }

  .view {
    width: 2.25em;

    & .icon {
      cursor: pointer;

      &:hover {
        color: #2196f3;
      }
    }
  }
}
