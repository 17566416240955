.role-creation-row {
  th {
    vertical-align: baseline;

    .p-component:only-child {
      width: 100%;
    }
  }

  .actions {
    &.edition {
      width: 4.5em;
    }

    .p-column-title {
      > svg {
        cursor: pointer;
        font-size: 1.15em;

        &.confim {
          color: #263797;
        }
      }
    }
  }
}
