.show-user {
  h1 {
    text-align: center;
    font-weight: 500;
    font-size: 3em;
  }

  .header {
    .p-button-label {
      display: none !important;
    }

    .p-button {
      min-width: 2.5em;
    }
  }

  .escopo {
    @media (max-width: 767px) {
      text-align: end !important;
      padding-right: 1em !important;
    }
  }

  .actions {
    width: 2.5em;

    & .icon-remover {
      &:hover {
        color: #f44336;
      }
    }

    button {
      border: none;
      background-color: transparent;

      &:focus {
        outline: none;
      }
    }

    > svg {
      display: inline;
      cursor: pointer;
    }
  }
}
